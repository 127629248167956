import { render, staticRenderFns } from "./PlayersComponent.vue?vue&type=template&id=39f42e32&scoped=true&"
import script from "./PlayersComponent.vue?vue&type=script&lang=js&"
export * from "./PlayersComponent.vue?vue&type=script&lang=js&"
import style0 from "./PlayersComponent.vue?vue&type=style&index=0&id=39f42e32&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39f42e32",
  null
  
)

export default component.exports